import Vue from 'vue';

// axios
import axios from 'axios';
// eslint-disable-next-line import/no-cycle
import store from '@/store';

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  // baseURL: 'http://localhost:5001/services/cms/api',
  baseURL: `${process.env.VUE_APP_BACKEND}/services/cms/api`,
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
});

// Request interceptor
axiosIns.interceptors.request.use(
  config => {
    store.commit('appConfig/TOGGLE_LOADING', true);

    const { accessToken } = store.state.auth;

    // If token is present add it to request's Authorization Header
    if (accessToken) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    return config;
  },

  error => {
    store.commit('appConfig/TOGGLE_LOADING', false);
    return Promise.reject(error)
  },
);

axiosIns.interceptors.response.use(function (response) {
  store.commit('appConfig/TOGGLE_LOADING', false);
  return response;
}, function (error) {
  store.commit('appConfig/TOGGLE_LOADING', false);
  return Promise.reject(error);
});

Vue.prototype.$http = axiosIns;

export default axiosIns;
