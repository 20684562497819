import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import SecureLS from 'secure-ls'

// Modules
import ecommerceStoreModule from '@/views/apps/e-commerce/eCommerceStoreModule'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import auth from './auth'

const ls = new SecureLS({ isCompression: false })

// Persist and secure local storage
const vuexLocalStorage = new VuexPersistence({
  key: 'lzaFwiLFwiYXJcIjpcIkFyYWJpY1wifSxcImZhbGxiYWNrTG9jYWxlXCI6XCJ',
  storage: {
    getItem: key => ls.get(key),
    setItem: (key, value) => ls.set(key, value),
    removeItem: key => ls.remove(key),
  },
  modules: ['auth'],
})

Vue.use(Vuex)

export default new Vuex.Store({
  state: {

  },
  mutations: {

  },
  getters: {
    isAppLoading(state) {
      return state.appLoading;
    }
  },
  modules: {
    app,
    appConfig,
    verticalMenu,
    auth,
    'app-ecommerce': ecommerceStoreModule,
  },
  strict: process.env.DEV,
  plugins: [vuexLocalStorage.plugin],
})

