export default {
  namespaced: true,
  state: {
    userData: null,
    accessToken: null,
    refreshToken: null,
    appLoading: false
  },

  getters: {
    isLoggedIn(state) {
      return !!(state.userData && state.accessToken)
    },
  },

  mutations: {
    TOGGLE_LOADING(state, value) {
      state.appLoading = value
    },
    SET_USER_DATA(state, userData) {
      state.userData = userData
    },
    SET_ACCESS_TOKEN(state, accessToken) {
      state.accessToken = accessToken
    },
    SET_REFRESH_TOKEN(state, refreshToken) {
      state.refreshToken = refreshToken
    },
  },
}
